import routerOptions0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_g27u4bnqodr5avinbxvhc2zvka/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/rialtic/workflows/console-ui/apps/console/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}