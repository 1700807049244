import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/account.vue").then(m => m.default || m)),
  admin: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/admin.vue").then(m => m.default || m)),
  blank: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/blank.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/default.vue").then(m => m.default || m)),
  form: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/form.vue").then(m => m.default || m)),
  signin: defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/console/layouts/signin.vue").then(m => m.default || m)),
  "claim-lab": defineAsyncComponent(() => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/layouts/claim-lab.vue").then(m => m.default || m)),
  "auth-public": defineAsyncComponent(() => import("#build/layouts.public.RrcVVm335i.vue").then(m => m.default || m))
}